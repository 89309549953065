import { Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteFromCart,
  IncreaseQuantity,
  DecreaseQuantity,
  getCourses,
  UpdateCartCourse
} from "../../store/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const Sortable = (props) => {
  const orders = useSelector((state) => state.order);
  const cartCourses = useSelector((state) => state.order.courses);

  // const [hover, setHover] = useState(false);
  const hover = false;

  let ListCart = [];

  const [data, setData] = useState([]); //final grouped items in this array, index of data represents index of courses
  const dispatch = useDispatch();
  const caourseObj = {
    antipasti: "Vorspeise",
    "main course": "Hauptgericht",
    dessert: "Nachtisch",
    "to drink": "Getränke",
    "Cocktails": "Coocktails"
  };
  const arrayCreator = (cardItems) => {
    var arr = [];

    for (var i = 0; i < cartCourses.length; i++) {
      arr[i] = [];
      for (var j = 0; j < cardItems?.length; j++) {
        if (cardItems[j].course === cartCourses[i].title) {
          arr[i].push(cardItems[j]);
        }
      }
    }
    setData(arr);
  };
  useEffect(() => {
    if (orders?.courses?.length <= 0) {
      dispatch(getCourses());
    }

    Object.keys(orders?.Carts?.flat()).forEach(function (item) {
      ListCart.push(orders?.Carts[item]);
    });
    arrayCreator(ListCart);
  }, [orders]);

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const findCartIndex = (id) => {
    return orders.Carts.findIndex((item) => item.id === id);
  };

  const itemPrice = (itemIndividual) => {
    let ingredientPrices = [];
    let restt = 0;
    let price = 0;
    let totalItemPrice = 0;

    price = (itemIndividual?.price * itemIndividual.quantity).toFixed(2);

    itemIndividual?.subItems?.forEach((ing) => {
      return ingredientPrices.push(Number(ing.price * Number(itemIndividual.quantity)));
    });

    restt = ingredientPrices.reduce(function (a, b) {
      return a + b;
    }, 0);
    if (typeof restt !== "number" || isNaN(restt)) {
      totalItemPrice = Number(price);
    } else {
      totalItemPrice = Number(restt) + Number(price);
    }
    return totalItemPrice;
  };

  const itemRenderer = (item, index, listInd) => {
    return (
      <>
        <div
          className={`item ${hover && "bg-light"}  container-fluid w-100`}
          style={{
            paddingBottom: "15px",
            backgroundColor: "#B393C4",
            paddingTop: "1px",
            paddingLeft: "10px",
            borderBottom: "1px solid #ddd"
          }}>
          <Row
            className="align-items-center text-left justify-content-between mt-3 overflow-auto"
            key={item?.id}>
            <Col sm={12} xs={12}>
              <h3
                style={{ paddingLeft: "15px" }}
                className={`d-inline text-dark orders-item fs-${props?.page_theme?.font_size_course_items_title} font-${props?.page_theme?.font_family_primary_course_items}`}>
                {item?.name}
                <h5 className={`d-inline text-dark orders-item showOn490 removeOn491`}>
                  {"  "} - {"  "} {itemPrice(item).toFixed(2)} €{" "}
                </h5>
              </h3>
            </Col>
            <Col lg={4} xs={6}>
              <h3 style={{ paddingLeft: "15px" }} className="d-inline text-dark orders-item">
                <div className="fs-6 fw-light ps-3">
                  {item?.subItems?.map((ing) => {
                    if (ing?.type === "normal" || ing?.type === "Extras") {
                      return (
                          <div key={uuidv4()} className="ingredient-wrapper">
                            <div className="name-wrapper">
                              <span className="order-ing-name">{ing?.ingredient_name}</span>
                            </div>
                            <div className="price-wrapper">
                              <span className="order-ing-price">{ing && ing?.price ? '€' : ''}</span>
                              <span className="price-text">{ing && ing?.price ? ing?.price : ''}</span>
                            </div>

                        </div>
                      );
                    }
                    return true;
                  })}
                </div>
              </h3>
              <Col style={{ paddingTop: "10px", display: "flex" }}>
                <span
                  className="btn btn-light"
                  style={{
                    margin: "2px",
                    marginLeft: "15px",
                    marginRight: "6px"
                  }}
                  onClick={() => {
                    const cIndex = findCartIndex(item.id);
                    dispatch(DecreaseQuantity(cIndex));
                  }}>
                  <i className="fas fa-minus"></i>
                </span>
                <span
                  className="d-flex align-items-center text-info"
                  style={{
                    paddingLeft: "18px",
                    paddingRight: "18px",
                    fontSize: "18px"
                  }}>
                  {item?.quantity}
                </span>
                <span
                  className="btn btn-light"
                  style={{ margin: "2px" }}
                  onClick={() => {
                    const cIndex = findCartIndex(item.id);
                    dispatch(IncreaseQuantity(cIndex));
                  }}>
                  <i className="fa-solid fa-plus"></i>
                </span>
              </Col>
            </Col>

            <Col style={{ padding: 0 }} lg={2} xs={2} className="removeOn490">
              <div className="d-flex justify-content-center">
                <h4 className="d-inline text-dark orders-item">
                  <u style={{ textDecoration: "auto" }}>{itemPrice(item).toFixed(2)} €</u>
                </h4>
              </div>
            </Col>
            <Col lg={2} xs={2}>
              <span
                className="fa-stack fa-lg fs-5 align-top"
                onClick={() => {
                  const newState = [...data];
                  newState[listInd].splice(index, 1);
                  setData(newState);
                  dispatch(DeleteFromCart(item));
                }}>
                <i className="fa fa-circle fa-stack-2x text-light"></i>
                <i className="fa fa-close fa-stack-1x fa-inverse text-dark"></i>
              </span>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} lg={2} xs={2}>
              <div className="d-flex justify-content-end">
                <img
                  src="/img/drag.png"
                  alt="dragicon"
                  height={30}
                  width={30}
                  style={{ opacity: "0.5" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  const dessertDrinkCheck = (arr, index) => {
    const sourceClone = [...arr];
    const [removed] = sourceClone.splice(index, 1);
    if (removed?.course === "dessert" || removed?.course === "to drink") {
      return true;
    }
    return false;
  };

  const allowedDrag = (dest) => {
    //drag allowed between antipasti and main course only
    if (dest === "main course" || dest === "antipasti") {
      return true;
    }
    return false;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(data[sInd], source.index, destination.index);
      const newState = [...data];
      newState[sInd] = items;
      setData(newState);
    } else {
      //drag allowed between antipasti and main course only
      //drinks and desserts not allowed to be draggeed outside list
      if (allowedDrag(cartCourses[dInd].title) && !dessertDrinkCheck(data[sInd], source.index)) {
        const result = move(data[sInd], data[dInd], source, destination);
        const newState = [...data];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
        setData(newState);

        let moved = {
          id: data[sInd][source.index].id,
          course: cartCourses[dInd].title
        };
        dispatch(UpdateCartCourse(moved));
      }
    }
  };
  return (
    <>
      <div style={{ display: "block", width: "100%" }}>
        <DragDropContext onDragEnd={onDragEnd} style={{ overflowY: "auto" }}>
          {data?.map((list, ind) => (
            <div key={uuidv4()}>
              <h3
                className={`orders-courses fs-${props.page_theme?.font_size_course_title} font-${props.page_theme?.font_family_primary_course_title}`}
                style={{
                  color: props.page_theme?.primary_course_title_text_color
                }}>
                {caourseObj[cartCourses[ind].title].toUpperCase()}
              </h3>
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {list?.map((item, index) => {
                      return (
                        <Draggable key={item?.id} draggableId={item?.id?.toString()}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              {itemRenderer(item, index, ind)}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </>
  );
};
