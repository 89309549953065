import React, { useState, useRef, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CanvasThanksModal from "../modals/CanvasThanksModal";
import { canvasPost, setLoadingCanvas } from "../../store/actions/canvasActions";
import "../../assets/css/orderBill.css";
import { v4 as uuidv4 } from "uuid";

export const Canvas = ({ page_theme }) => {
  const [brushColor, setBrushColor] = useState("black");
  const [fillColor, setFillColor] = useState("#ffff");
  const [brushRadius, setBrushRadius] = useState(4);
  const [bgImg, setBgImage] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [textArea, setTextArea] = useState(null);
  const [showFill, setShowFill] = useState(false);
  const [activeColorIndex, setActiveColorIndex] = useState(false);
  const canvasRef = useRef();

  const [canvas, setCanvas] = useState(window.innerWidth - 150);

  const businessID = useSelector((state) => state.table.orderInfo.businessLocationId);
  const canvasResponse = useSelector((state) => state.canvas.canvasPost);
  const canvasLoading = useSelector((state) => state.canvas.loading);
  const tableName = useSelector((state) => state.table.table);

  const [successMsg, setSuccessMsg] = useState(canvasResponse);
  const [canvasSuccesmsg, setCanvasSuccesmsg] = useState(false);

  const dispatch = useDispatch();
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  useEffect(() => {
    dispatch(setLoadingCanvas(false));
    setSuccessMsg();
    setBgImage(null);

    function handleResize() {
      setCanvas(window.innerWidth - 150);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [successMsg]);

  const postCanvas = async () => {
    let payload;
    if (showTextArea && textArea) {
      const ctx = canvasRef.current.canvasContainer.childNodes[0].getContext("2d");
      ctx.fillText("", 10, 50);
      ctx.font = "70px Quicksand Sans-serif";
      ctx.fontWeight = "bold";
      ctx.fillText(textArea, 10, 50);
      let canvasImage = canvasRef.current.canvasContainer.childNodes[0].toDataURL();
      payload = {
        blId: businessID,
        url: canvasImage
      };
    } else {
      let canvas_base64 = await canvasRef.current.getDataURL();
      let canvas_Image = await canvasRef.current.canvasContainer.childNodes[0].toDataURL();

      if (bgImg != null || undefined || "") {
        payload = {
          blId: businessID,
          url: canvas_Image
        };
      } else {
        payload = {
          blId: businessID,
          url: canvas_base64
        };
      }
    }

    dispatch(canvasPost(payload));

    if (canvasResponse.success === true) {
      setCanvasSuccesmsg(true);
      canvasRef.current.eraseAll();
      canvasRef.current.clear();
      const context = canvasRef.current.canvasContainer.childNodes[0].getContext("2d");
      context.clearRect(
        0,
        0,
        canvasRef.current.canvasContainer.childNodes[0].width,
        canvasRef.current.canvasContainer.childNodes[0].height
      );
      setBgImage();
      setTimeout(() => {
        setCanvasSuccesmsg(false);
      }, 7000);
    }
  };

  function openColorInput() {
    document.getElementById("ColorStrokeInput").click();
  }

  function getImageInput(e) {
    if (!e) {
      document.getElementById("ImageInput").click();
    } else {
      if (e.target.files && e.target.files[0]) {
        let img = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          if (e !== null) setBgImage(e.target?.result);
        };
        reader.readAsDataURL(img);
      }
    }
  }

  const colorPallete = [
    "#E61A3F",
    "#F9B62A",
    "#18B6C2",
    "#36AC56",
    "#304040",
    "#F6F3EC",
    "#FFFFFF"
  ];
  return (
    <>
      <CanvasDraw
        ref={canvasRef}
        hideGrid
        brushColor={brushColor}
        canvasWidth={canvas}
        imgSrc={bgImg ? bgImg : ""}
        brushRadius={brushRadius}
        enablePanAndZoom={true}
        style={{
          width: "100%",
          backgroundColor: fillColor,
          border: "2px solid gray",
          height: "calc(100vh - 460px)",
          position: "reletive"
        }}
      />

      {/* <Stack
        direction="horizontal d-inline"
        gap={5}
        className={
          "justify-content-between pe-5 ps-1 overflow-auto removeScroll"
        }
        
      > */}
      {showTextArea ? (
        <div
          className="ms-1"
          style={{
            backgroundColor: "transparent",
            height: "calc(100vh - 473px)",
            width: "83%",
            position: "absolute",
            bottom: "254px"
          }}>
          <b>
            <textarea
              className=" w-100 h-100 text-dark fs-1 markdown ms-1  "
              // autofocus
              value={textArea}
              onChange={(e) => {
                setTextArea(e.target.value);
              }}
              placeholder="Type Here..."
              maxLength="1000"
              style={{
                fontSize: "70px",
                fontWeight: "bold",
                border: "none",
                backgroundColor: "transparent",
                resize: "none"
              }}
            />
          </b>

          {/* </Stack> */}
        </div>
      ) : null}
      <div
        className={"justify-content-between pe-3 ps-3 "}
        style={{
          backgroundColor: "",
          marginTop: "-75px",
          paddingBottom: "10px",
          paddingTop: "10px",
          zIndex: "1"
        }}>
        <Stack
          direction="horizontal"
          gap={5}
          className={"justify-content-between pe-5 ps-1 overflow-auto removeScroll"}>
          <span className="text-dark fs-6">
            Table: {tableName} <br />
            {day} - {month} - {year}
          </span>
        </Stack>
      </div>

      <div className="text-center align-center">
        <div
          className={`${showPicker ? "justify-content-between pe-3 ps-3 " : "d-none"}`}
          style={{
            backgroundColor: "#DADADA",
            paddingBottom: "10px",
            paddingTop: "10px",
            zIndex: "1",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            marginTop: "5px"
          }}>
          <Form.Range
            className="p-2 pb-3 pt-2 text-dark"
            onChange={(e) => {
              setBrushRadius(e.target.value);
            }}
            value={brushRadius}
          />
          <Stack
            direction="horizontal"
            gap={5}
            className={"justify-content-between pe-3 ps-1 overflow-auto removeScroll"}>
            {colorPallete.map((color, index) => {
              return (
                <span
                  key={uuidv4()}
                  className={`rounded-circle ${
                    activeColorIndex === index ? `p-4 border border-info` : "p-3"
                  }`}
                  style={{
                    backgroundColor: color,
                    zIndex: "1",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setBrushColor(color);
                    setActiveColorIndex(index);
                  }}></span>
              );
            })}
          </Stack>
        </div>

        <div
          className={`${showFill ? "justify-content-between pe-3 ps-3 " : "d-none"}`}
          style={{
            backgroundColor: "#DADADA",
            paddingBottom: "10px",
            paddingTop: "10px",
            zIndex: "1",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            marginTop: "5px"
          }}>
          <Stack
            direction="horizontal"
            gap={5}
            className={"justify-content-between pe-3 ps-1 overflow-auto removeScroll"}>
            {colorPallete.map((color) => {
              return (
                <span
                  key={uuidv4()}
                  className="rounded-circle p-3"
                  style={{
                    backgroundColor: color,
                    zIndex: "1",
                    cursor: "pointer"
                  }}
                  onClick={() => setFillColor(color)}></span>
              );
            })}
          </Stack>
        </div>

        <Row className="justify-content-between align-items-center mt-3">
          <Col>
            <Form.Group controlId="formFileSm" className="">
              <Form.Control
                className="d-none"
                style={{ cursor: "pointer" }}
                id="ColorStrokeInput"
                defaultValue="#563d7c"
                title="Choose your color"
                value={brushColor}
                onChange={(e) => {
                  setBrushColor(e.target.value);
                }}
                onClick={() => {
                  setShowFill(false);
                  setShowPicker(!showPicker);
                  setShowTextArea(false);
                  setTextArea(null);
                }}
              />
              <img
                src="https://app1.va-piano.ch/img/paint-brush.png"
                style={{ cursor: "pointer" }}
                alt="color_picker"
                width={35}
                height={35}
                onClick={() => {
                  openColorInput();
                  setShowTextArea(false);
                  setTextArea(null);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <div
              onClick={() => {
                setShowPicker(false);
                setShowFill(!showFill);
                setShowTextArea(false);
                setTextArea(null);
              }}>
              <img
                src="/img/paintbucket.png"
                width={35}
                height={35}
                alt="paint"
                style={{ cursor: "pointer" }}
              />
            </div>
          </Col>
          <Col>
            <div>
              <Form.Control
                type="file"
                className="d-none"
                id="ImageInput"
                accept=".*"
                onChange={(e) => {
                  getImageInput(e);
                }}
              />
              <img
                src="/img/camera.png"
                width={35}
                height={35}
                alt="camera"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  getImageInput();
                  setShowTextArea(false);
                  setTextArea(null);
                }}
              />
            </div>
          </Col>

          <Col>
            <div>
              <img
                src="/img/mark.png"
                width={"35px"}
                height={"35px"}
                alt="camera"
                role={"button"}
                onClick={() => {
                  canvasRef.current.undo();
                  setTextArea(null);
                  setShowTextArea(!showTextArea);
                  setShowFill(false);
                  setShowPicker(false);
                }}
              />
            </div>
          </Col>
          <Col>
            <div className="">
              <img
                src="/img/circulararrrow.png"
                width={35}
                height={30}
                alt="arrow"
                onClick={() => {
                  setTextArea(null);
                  canvasRef.current.undo();
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </Col>
          <Col>
            <button
              style={{
                textAlign: "center",
                backgroundColor: page_theme?.primary_tick_btn_bgcolor,
                width: "35px",
                height: "30px",
                borderRadius: "5px",
                cursor: "pointer",
                border: "0px white"
              }}
              disabled={canvasLoading}
              className="text-center text-align-center "
              onChange={(e) => {
                setBrushColor(e.target.value);
              }}
              onClick={() => {
                postCanvas();
              }}>
              {canvasLoading ? (
                <span
                  className="spinner-border spinner-border-sm text-center"
                  role="status"
                  aria-hidden="true"></span>
              ) : (
                <img src="/img/checkmark.png" width={"20px"} alt="arrow" className="text-center" />
              )}
            </button>
          </Col>
        </Row>
      </div>

      <CanvasThanksModal
        canvasSuccesmsg={canvasSuccesmsg}
        setCanvasSuccesmsg={setCanvasSuccesmsg}
      />
    </>
  );
};
