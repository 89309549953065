import axios from "axios";
import {
  SET_TABLE,
  BUS_INFO,
  ORDER_INFO,
  SESSION_ID,
  RESET_TABLE_SESSION,
  SET_TABLE_LOADING,
  UPDATE_SESSION_TABLE_REQUEST,
  UPDATE_SESSION_TABLE_SUCCESSED,
  UPDATE_SESSION_TABLE_FAIL,
  GET_FLOOR_PLANS_SUCCESSED,
  GET_TABLE_ENABLE_PERMISSION
} from "../types";

export const getBusinessInfo = (payload) => async (dispatch) => {
  try {
    let res = await axios.post(`${process.env.REACT_APP_BackendURL}/business`, payload);

    dispatch({
      type: BUS_INFO,
      payload: res?.data
    });
  } catch (err) {
    alert("ERROR HEREE (getBusinessInfo)" + err);
  }
};
export const tableID = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SESSION_ID,
      payload
    });
  } catch (error) {
    alert("Something went wrong with your Session. n/ Please reload & try again:" + error);
  }
};
export const orderInfo = (payload) => {
  return {
    type: ORDER_INFO,
    payload
  };
};

export const setTable = (payload) => {
  return {
    type: SET_TABLE,
    payload
  };
};

export const resetTableSession = () => {
  return {
    type: RESET_TABLE_SESSION
  };
};
export const setTableLoading = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TABLE_LOADING,
      payload: payload
    });
  } catch (err) {
    console.log("error " + err);
  }
};
export const updateSessionTable = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SESSION_TABLE_REQUEST
    });
    let res = await axios.put(
      `${process.env.REACT_APP_BackendURL}/table-sessions/update-session-table`,
      payload
    );
    dispatch({
      type: UPDATE_SESSION_TABLE_SUCCESSED,
      payload: res?.data?.data?.table_id
    });
    return res?.data;
  } catch (err) {
    dispatch({
      type: UPDATE_SESSION_TABLE_FAIL
    });
    console.log("ERROR HEREE (updateSessionTable)" + err);
    return err;
  }
};
export const getBusinessFlorrPlans = (payload) => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/floor-plans/${payload}`);
    dispatch({
      type: GET_FLOOR_PLANS_SUCCESSED,
      payload: res?.data?.data?.sort((a, b) => {
        if (!b.number) {
          return -1;
        }
        return a.number - b.number;
      })
    });
  } catch (err) {
    dispatch(setTableLoading(false));
    alert("ERROR HEREE (getBusinessFlorrPlans)" + err);
  }
};
export const checkIsTableChangeEnable = () => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/guest-book`);
    if (res?.data?.success) {
      dispatch({
        type: GET_TABLE_ENABLE_PERMISSION,
        payload: res?.data?.data?.table_change
      });
    }
  } catch (err) {
    console.log(err)
  }
};