function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  export async function requestPermission() {
    const registration = await navigator.serviceWorker.register("/service-worker.js");
  
    if ("Notification" in window) {
     return window.Notification.requestPermission().then(result=> {
  
        if (result === "granted") {
          
          return   registration.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(
              process.env.REACT_APP_VAPI_PUBLIC_KEY // generated in one of previous steps
            ),
            userVisibleOnly: true
          }).then(res=>res);
        
        }
      })
      // If the user rejects the permission result will be "denied"
    } else {
      const permission = Notification.permission;
  
      if (permission === "default") {
        const result = await new Promise((resolve) => {
          Notification.requestPermission().then(resolve);
        });
        alert(result);
      }
    }
  }