/* eslint-disable no-unused-vars */
import { ClipboardCopy } from 'lucide-react';
import React, { useState } from 'react';
import "../../assets/css/style.css";
import { Button } from 'react-bootstrap';

const CopyUrlButton = () => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: '1rem',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        className='clipboard-button'
        onClick={handleClick}
        variant='outline-warning'
        size='sm'
      >
        <span>
          {copied ?
            <span style={{
              color: 'green',
            }}>
              Kopiert!
            </span>
            :
            <span style={{
              color: 'black',
            }}>
              URL kopieren
            </span>
          }
        </span>
        <span>  </span>
        <ClipboardCopy
          color={copied ? 'green' : 'black'}
          size={18}
        />
      </Button>
    </div>
  );
};

export default CopyUrlButton;
