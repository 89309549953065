
const getOffset = (el) => {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  };
export const handleSetActive = (e, _scrollWidth) => {
    let navbarMenu = document.getElementById("navbar-menu");
    let navLinkMenu = document.getElementsByClassName("nav-link-menu");
    let hasHScroll = _scrollWidth > navbarMenu.clientWidth;
    if (hasHScroll) {
      for (let navLink of Array.from(navLinkMenu)) {
        let innerHtml = navLink.innerHTML.replace("&amp;", "&");

        if (innerHtml.toLowerCase().includes(e.toLowerCase())) {
          navbarMenu.scrollLeft = getOffset(navLink).left;
          break;
        }
      }
    }
  };