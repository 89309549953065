/* eslint-disable no-unused-vars */
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { useSearchParams } from "react-router-dom";
import useIsSafari from "../../hooks/useSafariDetection";
import CopyUrlButton from '../home/copyUrlToClipboard';

const WelcomeModal = (props) => {
  let [searchParams] = useSearchParams();
  let table_no = searchParams.get("table_no");
  let busLocID = searchParams.get("buid");
  const [selectedType, setSelectedType] = useState("");
  const [isTableTypeSelectionModalOpen, setIsTableTypeSelectionModalOpen] = useState(props.display);
  const [isSafariModalOpen, setIsSafariModelOpen] = useState(false);

  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.welcome
  );
  const FloorPlanRepo = RepositoryFactory.get("floor_plans");
  useEffect(() => {
    if (busLocID && table_no) {
      verfiTableNotification()
    }
  }, [busLocID, table_no])
  const verfiTableNotification = () => {
    FloorPlanRepo.getFloorPlanByNumber(busLocID, table_no).then(
      (response) => {
        setSelectedType(response?.data.data[0]?.table_type)
      }
    )
      .catch((error) => {
        console.error("Error fetching floor plan:", error);
      });
  }

  const itsSafari = useIsSafari()
  const handleSelectType = (type) => {
    setSelectedType(type);
    props.onSelectType(type);
  };

  useEffect(() => {
    if (selectedType == "Pick-up" && itsSafari) {
      setIsTableTypeSelectionModalOpen(false)
      setIsSafariModelOpen(true)
    }
  }, [selectedType])

  return (
    <>
      <Modal
        show={isTableTypeSelectionModalOpen}
        onHide={null}
        backdrop="static"
        className={`${props.classname && props.classname} p-md-5 p-0`}
        centered
      >
        <Modal.Title className={props.className}>
          <div className="mb-3 red">
            <h1>{props.title}</h1>
          </div>
        </Modal.Title>

        <Modal.Body
          className={props.bodyClass}
          style={{ width: '100%', direction: 'flex', justifyContent: 'center', alignItems: "center" }}
        >
          <NonSafariModalContent
            props={props}
            setIsSafariModelOpen={setIsSafariModelOpen}
            handleSelectType={handleSelectType}
            page_theme={page_theme}
            selectedType={selectedType}
            setIsTableTypeSelectionModalOpen={setIsTableTypeSelectionModalOpen}
          />
        </Modal.Body>
      </Modal >


      <Modal
        show={isSafariModalOpen}
        onHide={null}
        backdrop="static"
        className={`${props.classname && props.classname} p-md-5 p-0`}
        centered
      >
        <Modal.Title className={props.className}>
          <div className="mb-3 red">
            <h1>{props.title}</h1>
          </div>
        </Modal.Title>

        <Modal.Body
          className={props.bodyClass}
          style={{ width: '100%', direction: 'flex', justifyContent: 'center', alignItems: "center" }}
        >
          <SafariModalContent
            setIsSafariModelOpen={setIsSafariModelOpen}
            page_theme={page_theme}
            handleCloseTableTypeModal={props.handleClose}
          />
        </Modal.Body>
      </Modal >
    </>
  );
};
export default WelcomeModal;


const SafariModalContent = ({
  setIsSafariModelOpen,
  handleCloseTableTypeModal
}) => {
  const downloadChromeFromAppstore = () => {
    window.open('https://apps.apple.com/de/app/google-chrome/id535886823', '_blank');
  };
  return (
    <>
      <div className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '.8rem',
        }}
      >
        <div
          style={{
            width: '100%',
            borderRadius: '10px',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '2px solid gray',
            border: '1px solid transparent',
            outlineColor: 'orange',
            backgroundColor: '#fff',
            color: '#000',
          }}
        >
          <h1
            style={{
              color: '#000',
              fontWeight: '600',
            }}
          >
            Chrome
          </h1>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >Laden Sie Chrome herunter</span>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >um Benachrichtigungen zu erhalten</span>
          <CopyUrlButton />
        </div>
        <button
          style={{
            width: '100%',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '1px solid red',
            border: '1px solid transparent',
            backgroundColor: 'red',
            color: 'white',
          }}
          onClick={() => {
            downloadChromeFromAppstore()
            // setIsSafariModelOpen(false)
            // handleCloseTableTypeModal(false)
          }}
        >
          Herunterladen
        </button>
        <button
          style={{
            width: '100%',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '1px solid gray',
            border: '1px solid transparent',
            backgroundColor: 'gray',
            color: 'white',
          }}
          onClick={() => {
            setIsSafariModelOpen(false)
          }}
        >
          Ohne Benachrichtigung weitermachen
        </button>
      </div>
    </>

  )
}

const NonSafariModalContent = ({
  selectedType,
  props,
  handleSelectType,
  page_theme,
  setIsTableTypeSelectionModalOpen
}) => {
  const [tableType, setTableType] = useState("")
  return (
    <>
      <div className="mb-3">
        <button
          style={{
            width: '100%',
            borderRadius: '10px',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '3px solid gray',
            border: '1px solid transparent',
            outlineColor: tableType == "Standard-Service" ? 'red' : 'gray',
            backgroundColor: '#fff',
          }}
          onClick={() => setTableType("Standard-Service")}
        >
          <h1
            style={{
              color: '#000',
            }}
          >Tischbedienung</h1>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >Getränke und Speisen</span>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >werden an deinen Tisch gebracht</span>
        </button>
      </div>
      <div className="mb-3">
        <button
          style={{
            width: '100%',
            borderRadius: '10px',
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '3px solid gray',
            border: '1px solid transparent',
            outlineColor: tableType == "Pick-up" ? 'red' : 'gray',
            backgroundColor: '#fff',
            color: '#000',
          }}
          onClick={() => setTableType("Pick-up")}
        >
          <h1
            style={{
              color: '#000',
              fontWeight: '600',
            }}
          >
            Pickup
          </h1>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >Du hoist Bestellung selbst vor</span>
          <span
            style={{
              color: 'gray',
              fontWeight: '600',
            }}
          >der küche ab</span>
        </button>
      </div>

      <Button
        type="submit"
        style={{
          borderRadius: '10px',
          width: '100%',
          backgroundColor: page_theme?.primary_create_table_btn_bgcolor,
          color: page_theme?.primary_create_table_btn_text_color,
          fontSize: '16px',
        }}
        className={`btntable btnCreate me-2 mb-3 mt-2 fs-${page_theme?.font_size_create_table_btn} font-${page_theme?.font_family_create_table_btn}`}
        onClick={() => {
          props.onSubmit()
          handleSelectType(tableType)
          if (tableType != 'Pick-up') {
            setIsTableTypeSelectionModalOpen(false)
          }
        }}
      >
        WEITER
      </Button>
    </>
  )
}
