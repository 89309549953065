import React, { useEffect, useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  postOrder,
  SetLoading,
  setOrderSuccess,
  loadOrdersBySession
} from "../store/actions/orderAction";
import { Sortable } from "../components/order/Sortable";
import { v4 as uuidv4 } from "uuid";
import ModalInterface from "../common/ModalInterface";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
// import PreviousOrders from "../components/order/previousorders";
import PreviousOrderDesing from "../components/order/previousOrderDesing";

const Order = () => {
  const [errorMsg, setErrorMsg] = useState();
  const orders = useSelector((state) => state.order);
  const orderInfo = useSelector((state) => state.table.orderInfo);
  const sessionId = useSelector((state) => state.table.sessionId);

  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.cart);
  const payload = {
    businessLocationId: orderInfo?.businessLocationId,
    sessionId: sessionId
  };
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showFinal, setShowFinal] = useState(false);
  const [_, setPrepTime] = useState("00:00:00");
  console.log(_)
  const [showPendingpayment, setShowPendingpayment] = useState(false);
  const [images] = useState(["pasta", "antipasti", "pinsa", "bibite", "insalata", "risotto"]);

  const takeAway = useSelector((state) => state.menu.takeAway);
  const [errorMoadal, setErrorMoadal] = useState(false);
  const [takeAwayModal, setTakeAwayModal] = useState(false);
  const [takeAwayValue, setTakeAwayValue] = useState("00:00:00");
  const takeAwayInputEvent = (event) => {
    setTakeAwayValue(event.target.value);
  };
  const NetworkError = () => {
    setErrorMsg("You Are Offline!");
    setErrorMoadal(true);
  };
  const showTakeawayMOdal = () => {
    if (takeAway === "true") {
      setShowFinal(false);
      setTakeAwayModal(true);
    } else if (takeAway === "false") {
      setTakeAwayModal(false);
    }
  };

  useEffect(() => {
    if (orders?.loading) {
      dispatch(SetLoading(false));
    }
    if (orders.orderSuccess === true) {
      dispatch(setOrderSuccess(false));
    }
    if (payload?.businessLocationId && payload?.sessionId) {
      dispatch(loadOrdersBySession(payload));
    }
  }, []);
  // useEffect(() => {

  // }, [payload]);

  const calculateTimeToOrder = async () => {
    let PrepTimes = [];
    await orders?.Carts?.forEach((item) => {
      PrepTimes.push(item?.prepTime);
    });

    let calculatedTime = 0;

    calculatedTime = Math.max(...PrepTimes) || "0:00";
    setPrepTime(calculatedTime);
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          arrows: false
        }
      }
    ]
  };

  const orderPlacement = {
    businessLocationId: orderInfo?.businessLocationId,
    thirdPartyReference: uuidv4(),
    orderNote: "Test Order",
    orderType: null,
    sessionID: sessionId,
    customerInfo: {
      firstName: "Test",
      lastName: "Doe",
      contactNumberAsE164: "012345678",
      notes: "nothing",
      salutation: "null"
    },
    tableNumber: orderInfo?.tableNumber,
    items: []
  };

  useEffect(() => {
    calculateTimeToOrder();
    window.scrollTo({ top: 110 });
  }, []);

  // useEffect(() => {
  //   if (orders?.orderSuccess === "pending" ) {
  //     setShowFinal(true);
  //   }
  // }, [orders?.orderSuccess]);

  // useEffect(() => {
  //   if (orders?.ordersPlaced.length > 0) {
  //     setShowFinal(true);
  //   }
  // }, [orders?.ordersPlaced]);
  const [isSticky] = useState(true); // Set to true by default
  const [topPosition, setTopPosition] = useState('75%'); // Default top position

  useEffect(() => {

    const handleResize = () => {
      // Calculate top position based on viewport height
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 111;
      setTopPosition(isSticky ? `${viewportHeight * 0.9}px` : 'auto');
    };

    window.addEventListener('resize', handleResize, { passive: true });

    // Initial resize
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSticky]);

  return (
    <>
      <div
        style={{
          paddingBottom: "100px",
          paddingRight: "20px",
          marginBottom: "40px"
        }}
        className=" mt-4 pt-4">
        <div className="logo" style={{ backgroundColor: "#896CA7" }}>
          <div className="mainLogo">
            <img
              src={"/img/vapiano_logo.png"}
              alt="main_logo"
              width="85%"
              style={{ maxWidth: "280px", verticalAlign: "middle" }}
            />
          </div>
        </div>
        <Stack direction="horizontal" gap={5} className="align-items-center mt-4">
          <h1 className="orders-title text-uppercase">Warenkorb</h1>
          <img
            src={"/img/order.png"}
            className="img-fluid ms-auto"
            width={120}
            height={61}
            alt="order"
          />
        </Stack>
        <Sortable showFinal={showFinal} page_theme={page_theme} />
        <Stack direction="horizontal">
          {orders?.Carts?.length === 0 ? (
            <Button
              variant="col-2"
              style={{
                borderRadius: "30px",
                border: "none",
                width: "350px",
                color: page_theme?.primary_place_order_btn_text_color,
                backgroundColor: page_theme?.primary_place_order_btn_bgcolor
                // fontSize: "16px",
              }}
              className={`ms-auto mt-5 me-4 font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn}`}
              disabled>
              BESTELLUNG AUFGEBEN
            </Button>
          ) : (
            <div className="d-flex justify-content-end w-100" style={{ position: isSticky ? 'fixed' : 'static', top: topPosition , zIndex: 1000}}>
        <Button
          variant="info text-light col-2"
          style={{
            borderRadius: "30px",
            width: "350px",
            border: "none",
            color: page_theme?.primary_place_order_btn_text_color,
            backgroundColor: page_theme?.primary_place_order_btn_bgcolor,
            padding: "6px"
          }}
                disabled={orders.loading}
                className={`"ms-auto mt-5 me-4 font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn}"`}
                onClick={() => {
                  if (
                    orders?.ordersPlaced[0]?.payment_status === "paid" ||
                    orders?.ordersPlaced[0]?.payment_status === "partially_paid"
                  ) {
                    setShowPendingpayment(true);
                    return;
                  }
                  if (orderInfo?.businessLocationId?.length > 0 && sessionId?.length > 0) {
                    if (!window?.navigator?.onLine) {
                      // alert("Network Error You are offline !");
                      NetworkError();
                      return;
                    }

                    if (orders?.Carts?.length > 0) {
                      if (takeAway === "true") {
                        showTakeawayMOdal();
                      } else {
                        if (
                          orders?.Carts?.items?.length === 0 ||
                          !orderPlacement?.tableNumber ||
                          !orderPlacement?.businessLocationId
                        ) {
                          setErrorMsg("Make sure you have: 1- Add items to cart 2- Joined a table");
                          setErrorMoadal(true);
                          return;
                        }
                        Object.assign(orderPlacement, {
                          orderType: "served"
                        });
                        dispatch(SetLoading(true));
                        dispatch(postOrder(orderPlacement, orders.Carts)).then((res) => {
                          if (res == 200) {
                            setTimeout(() => {
                              navigate("/bill");
                            }, 5000);
                            setShowFinal(true);
                          }
                        });
                      }
                    }
                  } else {
                    setErrorMsg("You don`t have required things!");
                    setErrorMoadal(true);
                  }

                  // } else {
                  //   setShowAlert(true);
                  // }
                }}>
                {orders.loading ? (
                  <span
                    className="spinner-border spinner-border-lg text-danger"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  <>BESTELLUNG AUFGEBEN</>
                )}
              </Button>
            </div>
          )}
        </Stack>
        {/* <PreviousOrders /> */}
        <PreviousOrderDesing />

        <ModalInterface
          display={showFinal}
          handleClose={() => {
            setShowFinal(false);
          }}
          title={<></>}
          closeEvent={() => setShowFinal(false)}
          content={
            <>
              <div
                className="closeBtnDivide"
                data-bs-dismiss="modal"
                onClick={() => setShowFinal(false)}>
                <i className="fas fa-times"></i>
              </div>
              <div className="text-center ">
                <div className="text-center text-uppercase thankuText">DANKE!</div>
                <div className="text-center text-dark forOrderText pt-3">
                  WIR HABEN DEINE BESTELLUNG ERHALTEN
                </div>
              </div>
              <h3 className="text-uppercase orderModalText">
                Unsere Mitarbeiter machen sich gleich an die Arbeit...
              </h3>
              <div className="text-center divideCalc3 mb-2">
                <Slider {...settings}>
                  {images.map((url) => (
                    <i key={uuidv4()}>
                      <img
                        src={`./img/placeorder/${url}.png`}
                        alt="carousel"
                        height={60}
                        width={60}
                      />
                    </i>
                  ))}
                </Slider>
                {/* <div>
                  <h3>
                    DU KANNST JEDER MOMENT BEIM KELLNER ODER ONLINE BEZAHLEN INDEM DU AUF
                    „BESTELLUNG“ CLICKST.
                  </h3>
                </div> */}
              </div>
            </>
          }
        />
      </div>
      <ModalInterface
        display={takeAwayModal}
        handleClose={() => {
          setTakeAwayModal(false);
        }}
        title={<></>}
        closeEvent={() => setTakeAwayModal(false)}
        content={
          <>
            <div
              className="closeBtnDivide"
              data-bs-dismiss="modal"
              onClick={() => setShowFinal(false)}>
              <i className="fas fa-times"></i>
            </div>
            <div className="text-center ">
              <div className="text-center text-uppercase thankuText">Take Away</div>
            </div>
            <div className="divideCalc3">
              <div className="order-description">
                <Form.Label>Take Away Time</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="#"
                  value={takeAwayValue}
                  onChange={takeAwayInputEvent}
                />
              </div>
              <div className="divideBottomBtn">
                <button
                  onClick={() => {
                    // {
                    //   ...orderPlacement,
                    //   takeaway_time: takeAwayValue,
                    // }
                    if (
                      orders?.Carts?.items?.length === 0 ||
                      !orderPlacement?.tableNumber ||
                      !orderPlacement?.businessLocationId
                    ) {
                      setErrorMsg("Make sure you have: 1- Add items to cart 2- Joined a table");
                      setErrorMoadal(true);
                      return;
                    }
                    Object.assign(orderPlacement, {
                      takeaway_time: takeAwayValue,
                      orderType: "pickup"
                    });
                    dispatch(postOrder(orderPlacement, orders.Carts)).then((res) => {
                      if (res === 200) {
                        setTimeout(() => {
                          navigate("/bill");
                        }, 5000);
                      }
                    });
                  }}
                  style={{ width: "50%", margin: "auto" }}>
                  Takeaway
                </button>
              </div>
            </div>
          </>
        }
      />
      <ModalInterface
        display={showPendingpayment}
        center="true"
        handleClose={() => {
          setShowPendingpayment(false);
        }}
        title={
          <>
            {" "}
            <div className="text-center  d-flex justify-content-end">
              <div
                className="bg-danger text-white close-btn d-flex align-items-center justify-content-center p-3 mt-1 me-1"
                role="button">
                <i
                  className="fa fa-close fs-5"
                  onClick={() => {
                    setShowPendingpayment(false);
                  }}></i>
              </div>
            </div>
          </>
        }
        closeEvent={() => setShowPendingpayment(false)}
        content={
          <>
            <div className="text-center divideCalc3 mb-2">
              <h5 className="text-danger">UUPS </h5>
              <h5>
                ES SCHEINT ALS WURDE BEREITS ETWAS BEZAHLT. SOBALD ETWAS BEZAHLT WURDE, KÖNNEN KEINE
                WEITEREN BESTELLUNGEN AUF DIESEN TISCHNAMEN ABGEGEBEN WERDEN. UM DENNOCH ETWAS ZU
                BESTELLEN, MUSST DU ERNEUT DEN TISCH SCANNEN UND EINEN NEUEN TISCHNAMEN EINGEBEN.
              </h5>
            </div>
          </>
        }
      />
      <ModalInterface
        display={errorMoadal}
        center="true"
        handleClose={() => {
          setErrorMoadal(false);
        }}
        title={<></>}
        closeEvent={() => setErrorMoadal(false)}
        content={
          <>
            <div className="text-center divideCalc3 mb-2">
              <div className="text-center divideCalc3 mb-2">{errorMsg}</div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Order;
