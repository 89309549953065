import { Form } from "react-bootstrap";
import React from "react";

const PaypalModal = (props) => {
  return (
    <>
      <div className="closeBtnDivide" data-bs-dismiss="modal" onClick={() => props.handleClose()}>
        <i className="fas fa-times"></i>
      </div>

      {/* <Form className=""> */}
      <div className="divideCalc2">
        <div className="paypal-sec">
          <h3 className="paypalHead">Pay with PayPal</h3>
          <p>
            With a PayPal account, you`&#39;`re eligible for free return shipping, Purchase
            Protection, and more
          </p>
        </div>
        <Form className="paypalform">
          <Form.Control type="email" size="lg" placeholder="Email" className="mt-3" />
          <Form.Control type="password" size="lg" placeholder="Password" className="mt-3" />

          {/* <Form.Check
          type={"checkbox"}
          className="mt-2 "
        /> */}
          {/* <label value='Stay logged in for faster purchases' /> */}
          <div className="stayLog">
            <label className="container">
              {/* <Form.Check type={'checkbox'} style={{ textAlign: 'left', marginTop: '10px' }}> */}
              {/* <Form.Check.Input className="checkmark" type={'checkbox'} isValid /> */}
              <span>Stay logged in for faster purchases</span>
              <input type="checkbox" />
              <span className="checkmark"></span>
              {/* <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback> */}
              {/* </Form.Check> */}
            </label>
          </div>
        </Form>
        <button
          size="md"
          className="button-paypal-login"
          // style={{ backgroundColor: '#0070ba', width: '450px' }}
          onClick={() => {
            props.setShowPaypal(false);
            // props.setShowFinal(true);
            props.setmodalProceed(true);
          }}
        >
          Log In
        </button>
      </div>
    </>
  );
};

export default PaypalModal;
