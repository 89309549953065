import React, { useEffect } from "react";
import "../../assets/css/paymentsuccess.css";
const PaymentSuccessComplete = (props) => {
  const buid = localStorage.getItem("buid");
  const table_no = localStorage.getItem("table_no");
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(`/?buid=${buid}&table_no=${table_no}`);
    }, 60000);
  }, []);
  return (
    <>
      <div className="bgcolor-white ">
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <div className="paymentlogocomplete">
            <img className="w-180 h-90" src="./img/vapiano_logo.png" alt="icon"></img>
          </div>
          <div className=" justify-content-center align-items-center d-flex flex-column">
            <div className="hearttitlecomplete">
              <img className="w-200 h-80" src="./img/paycomplete.png" alt="icon"></img>
            </div>
          </div>
          {props?.payment_type === "pay_waiter" ? (
            <div className="blacktitle text-center text-black text-upercase">
              EIN(E) KELLNER*IN<br />
              KOMMT IN KÜRZE<br />
              AN DEINEN TISCH
            </div>
          ) : (
            <div className="blacktitle text-center text-black text-upercase">
              WIR HABEN DEINE<br></br>
              <p>ZAHLUNG ERHALTEN</p>
            </div>
          )}
          {/* <div className="timerpayment text-uppercase">
            <span className="timer-clock me-4">
              <i class="fa-regular fa-clock " style={{ color: "#17B5C1" }}></i>
            </span>
            <span>
              <Timer
                duration={60000}
                hours
                seconds
                minutes
                onCompletion={() => {
                  window.location.replace(
                    `/?buid=${buid}&table_no=${table_no}`
                  );
                }}
              />
            </span>
          </div> */}
          {props?.payment_type !== "pay_waiter" ? (
            <div
              style={{
                fontSize: "21px",
                fontWeight: "600",
                marginBottom: "100px"
              }}
            >
              <div> DEIN BELEG LIEGT ZUR ABHOLUNG BEREIT.</div>
              <div>BITTE WENDE DICH AN EINE(N) MITARBEITER*IN.</div>
            </div>
          ) : null}
          {/* <div className="text-center text-uppercase text-white HIER ">
            HIER BEKOMMST DU <br />
            DEINE RECHNUNG
            <div>
              <button
                disabled={ordersPlaced[0]?.orderReference ? false : true}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/print-receipt?orderReference=${ordersPlaced[0]?.orderReference}&&buid=${props?.buid}`
                  );
                }}
                className="rechnungBtn"
              >
                RECHNUNG
              </button>
        </div>
      </div> */}
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessComplete;
