import React from "react";
import ModalInterface from "../../common/ModalInterface";

const CanvasThanksModal = ({ canvasSuccesmsg, setCanvasSuccesmsg }) => {
  return (
    <>
      <ModalInterface
        display={canvasSuccesmsg}
        size="sm"
        center={true}
        style={{ position: "relative" }}
        handleClose={() => {
          setCanvasSuccesmsg(false);
        }}
        content={
          <>
            <div className="text-center">
              <h1 className="text-center" style={{ color: "rgb(255, 186, 36" }}>
                DANKE
              </h1>
              <h1 className="text-center">WIR HABEN DEINE EINTRAGE ERHALTEN</h1>
            </div>
          </>
        }
        closeEvent={() => {
          setCanvasSuccesmsg(false);
        }}
      />
    </>
  );
};

export default CanvasThanksModal;
