import { Button, Form, Stack } from "react-bootstrap";
import React from "react";

const CreditCardModal = (props) => {
  return (
    <>
      <div className="closeBtnDivide" data-bs-dismiss="modal" onClick={() => props.handleClose()}>
        <i className="fas fa-times"></i>
      </div>
      <Form>
        <h1
          style={{
            fontSize: "21px",
            fontWeight: 400,
            fontFamily: "Heebo, sans-serif",
            textAlign: "left",
            marginBottom: "10px"
          }}
        >
          Enter Credit Card Info
        </h1>

        <Stack direction="horizontal" gap={4} className="cardNum">
          <label
            className="cardOption"
            style={{
              fontSize: "18px",
              fontFamily: "Heebo, sans-serif"
            }}
          >
            Card Number
          </label>
          <Form.Control type="text" size="md" placeholder="" style={{ width: "40px" }} />
          <Form.Control type="text" size="md" placeholder="" style={{ width: "40px" }} />
          <Form.Control type="text" size="md" placeholder="" style={{ width: "40px" }} />
          <Form.Control type="text" size="md" placeholder="" style={{ width: "40px" }} />
        </Stack>
        <Stack direction="horizontal" className="cvvNum">
          <label
            className="cardOption"
            style={{
              fontSize: "18px",
              fontFamily: "Heebo, sans-serif"
            }}
          >
            CVV Number
          </label>
          <Form.Control
            type="text"
            size="md"
            placeholder=""
            style={{ width: "40px" }}
            className="ms-4"
          />
        </Stack>
        <Stack direction="horizontal" className="dobNum">
          <label
            className="cardOption"
            style={{
              marginRight: "20px",
              fontSize: "18px",
              fontFamily: "Heebo, sans-serif"
            }}
          >
            Date of Birth
          </label>
          <input
            type="date"
            size="md"
            placeholder=""
            style={{ width: "130px" }}
            className="text-center"
          />
        </Stack>
        <Stack direction="horizontal" className="justify-content-end" gap={2}>
          <Button
            size="md"
            className="mt-3"
            style={{ backgroundColor: "#0095d9", width: "70px" }}
            onClick={() => {
              props.setShowCreditCard(false);
              props.setmodalProceed(true);
            }}
          >
            Proceed
          </Button>
          <Button
            size="md"
            className="mt-3"
            style={{ backgroundColor: "#fff", width: "70px", color: "black" }}
            // onClick={() => { props.setShowCreditCard(false); props.setShowFinal(true) }}
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </>
  );
};

export default CreditCardModal;
