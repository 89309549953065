import { firebase } from "../firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import {
  ADD_TO_CART,
  UPDATE_CART,
  DELETE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  POST_ORDER,
  GET_COURSES,
  ADD_INGREDIENTS,
  DELETE_INGREDIENTS,
  GET_CART_LENGTH,
  ADD_TO_TEMP_CART,
  UPDATE_CART_COURSE,
  SYNC_CARTS,
  GET_ORDERS_BY_SESSION,
  SET_LOADING,
  ORDER_PAYMENT,
  SET_ORDER_PAYMENT,
  EMPTY_ORDERS_PLACED,
  SET_PAYMENT_STATUS,
  SET_PLACED_LOADING,
  SET_ORDER_SUCCESS,
  GET_ORDERS_BY_FIREBASE
} from "../types";

let ordersRepository = RepositoryFactory.get("order");

export const AddIngredients = (payload) => {
  return {
    type: ADD_INGREDIENTS,
    payload
  };
};

export const DeleteIngredients = (payload) => {
  return {
    type: DELETE_INGREDIENTS,
    payload
  };
};

export const AddToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload
  };
};

export const AddToTempCart = (payload) => {
  return {
    type: ADD_TO_TEMP_CART,
    payload
  };
};

export const UpdateCart = (payload) => {
  return {
    type: UPDATE_CART,
    payload
  };
};

export const ClearTempCart = () => {
  return {
    type: "EMTY_TEMP_CART",
    payload: []
  };
};

export const DeleteFromCart = (payload) => {
  return {
    type: DELETE_FROM_CART,
    payload
  };
};

export const IncreaseQuantity = (payload) => {
  return {
    type: INCREASE_QUANTITY,
    payload
  };
};

export const DecreaseQuantity = (payload) => {
  return {
    type: DECREASE_QUANTITY,
    payload
  };
};

export const UpdateCartCourse = (payload) => {
  return {
    type: UPDATE_CART_COURSE,
    payload
  };
};

export const GetCartLength = () => {
  return {
    type: GET_CART_LENGTH
  };
};

export const getCourses = () => async (dispatch) => {
  try {
    let { data } = await ordersRepository.loadCourses();
    if (data?.success) {
      dispatch({
        type: GET_COURSES,
        payload: data?.data
      });
    }
  } catch (err) {
    alert("Something went wrong try again" + err);
  }
};

export const setOrderSuccess = (payload) => async (dispatch) => {
  dispatch({ type: SET_ORDER_SUCCESS, payload: payload });
};
function selectFewerProps(show) {
  const { name, quantity, sku, course, subItems, price } = show;
  // const station = course;
  return { name, quantity, sku, course, subItems, price };
}
export const postOrder = (payload, Cart) => async (dispatch) => {
  if (Cart !== null) {
    const items = Cart.map(selectFewerProps);
    payload.items = [...items];
  }

  // if (payload.items.length === 0 || !payload.tableNumber || !payload.businessLocationId) {
  //   alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
  //   return window.location.replace("/home");
  // }

  const checkOrder = (statusInfo) => {
    if (statusInfo?.status === 200) {
      dispatch({
        type: POST_ORDER,
        payload: { statusInfo, payload }
      });
      return statusInfo.status;
    } else {
      alert("order failed");
      return statusInfo.status;
    }
  };

  try {
    let statInfo = await ordersRepository.orderPlacement(payload);
    let res = checkOrder(statInfo);
    return res;
  } catch (err) {
    dispatch(SetLoading(false));
    alert("Order Failed:" + " " + err?.response?.data?.message);
    if (err.response?.data?.logout) {
      window.location.replace("/");
    }
    return err.response?.status;
  }
};

export const payWaiter = (payload) => async (dispatch) => {
  if (!payload.tableNumber || !payload.businessLocationId) {
    alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
    return window.location.replace("/home");
  }

  const checkOrder = (statusInfo) => {
    if (statusInfo?.status === 200) {
      dispatch({
        type: POST_ORDER,
        payload: { statusInfo, payload, redirect: true }
      });
      dispatch(
        loadOrdersBySession({
          businessLocationId: payload?.businessLocationId,
          sessionId: payload?.sessionId
          // table_no: payload?.tableNumber,
        })
      );
      return statusInfo;
    } else {
      alert("order failed");
    }
  };

  try {
    ordersRepository.payWaiterOrder(payload).then((statInfo) => checkOrder(statInfo));
  } catch (err) {
    dispatch(SetLoading(false));
    alert("Order Failed:" + " " + err?.response?.data?.message);
  }
};

export const paySelectedWaiter = (payload) => async (dispatch) => {
  if (!payload.tableNumber || !payload.businessLocationId) {
    alert("Make sure you have: \n 1- Add items to cart \n 2- Joined a table");
    return window.location.replace("/home");
  }

  const checkOrder = (statusInfo) => {
    if (statusInfo?.status === 200) {
      dispatch({
        type: POST_ORDER,
        payload: { statusInfo, payload, redirect: true }
      });
      dispatch(
        loadOrdersBySession({
          businessLocationId: payload?.businessLocationId,
          sessionId: payload?.sessionId
          // table_no: payload?.tableNumber,
        })
      );
      return statusInfo;
    } else {
      alert("order failed");
    }
  };
  try {
    ordersRepository
      .paySelectedWaiterOrder({
        sessionId: payload.sessionId,
        selectedIds: payload.selectedIds
      })
      .then((statInfo) => {
        checkOrder(statInfo);
      });
  } catch (err) {
    dispatch(SetLoading(false));
    alert("Order Failed:" + " " + err?.response?.data?.message);
  }
};
export const Sync_Carts = (payload) => {
  return {
    type: SYNC_CARTS,
    payload
  };
};

export const SetLoading = (payload) => {
  return {
    type: SET_LOADING,
    payload
  };
};
export const SetOrderPlacedLoading = (payload) => {
  return {
    type: SET_PLACED_LOADING,
    payload
  };
};
export const SetOrderPayment = (payload) => {
  return {
    type: SET_ORDER_PAYMENT,
    payload
  };
};

export const setPaymentStatus = (payload) => {
  return {
    type: SET_PAYMENT_STATUS,
    payload: payload
  };
};

export const loadOrdersBySession = (payload) => async (dispatch) => {
  // const checkPaymentType = (paymentInfo) => {
  //   if (paymentInfo?.data?.data[0]?.payment_status === "partially_paid") {
  //   }
  // };

  const checkOrders = (resInfo) => {
    if (resInfo?.status === 200) {
      dispatch({
        type: GET_ORDERS_BY_SESSION,
        payload: resInfo
      });
      // if (Object.prototype.hasOwnProperty.call(payload, "table_no")) {
      //   checkPaymentType(resInfo);
      // }
    } else {
      alert("Please refresh & try again");
    }
  };

  try {
    ordersRepository.getOrdersBySession(payload).then((resInfo) => checkOrders(resInfo));
  } catch (err) {
    alert("session order");
    if (err?.response?.data?.message !== undefined) {
      console.log("error", err?.response?.data?.message);
    }

    dispatch(SetLoading(false));
  }
};

export const makeOrderPayment = (payload) => async (dispatch) => {
  const checkOrderPayment = (resInfo) => {
    if (resInfo?.status === 200) {
      dispatch({
        type: ORDER_PAYMENT,
        payload: payload.tableInfo
      });
      dispatch(
        loadOrdersBySession({
          businessLocationId: payload?.tableInfo?.businessLocationId,
          sessionId: payload?.sessionInfo,
          table_no: payload?.tableInfo?.tableNumber
        })
      );
      // todo need to change flow its just a quick fix
      sessionStorage.setItem('paymentStarted', false)
      dispatch(setPaymentStatus(true));
    } else {
      alert("Order placement failed. Please refresh this page & try again");
    }
  };

  try {
    let resInfo = await ordersRepository.orderPayment(payload);
    checkOrderPayment(resInfo);
  } catch (err) {
    alert(
      "Order placement failed. Please refresh this page & try again",
      err?.response?.data?.message
    );
  }
};

export const listentoOrderChanges = (id) => async (dispatch, getState) => {
  const state = getState();
  if (id !== "") {
    //IMPORTANT
    //FIREBASE ORDERS USE STATUS FIELD TO DENOTE PAID/PARTIALLY-PAID/PENDING
    firebase
      .firestore()
      .collection("Local_Order")
      .doc(id)
      .onSnapshot(async (query) => {
        let order = { id: query.id, ...query.data() };
        const buid = localStorage.getItem("buid");
        const table_no = localStorage.getItem("table_no");
        if (order?.status === "paid" || order?.message === "Order has been fully paid") {
          await firebase.firestore().collection("Local_Order").doc(order?.sessionId);
          try {
            // localStorage.clear();
            // dispatch({
            //   type: "LOG_OUT"
            // })
            sessionStorage.setItem("paymentStarted", false)
            localStorage.setItem("paymentStatus", true);
          } catch (error) {
            console.log("ERROR " + error);
          }

          if (!state.order.paymentStatus) {
            sessionStorage.setItem("paymentStarted", false)
            dispatch(setPaymentStatus(true));
          }

          if (order?.payment_type === "pay_waiter") {
            window.location.replace(`/?buid=${buid}&table_no=${table_no}`);
          } else {
            if (
              window.location.pathname !== "/paymentsuccess" &&
              window.location.pathname !== "/print-receipt"
            ) {
              window.location.replace(`/?buid=${buid}&table_no=${table_no}`);
              return;
            }
            return;
          }

          // redirect user to home page
        } else if (
          order?.status === "partially_paid" &&
          order?.message !== "Order has been fully paid"
        ) {
          dispatch(setPaymentStatus(false));
          await firebase
            .firestore()
            .collection("Local_Order")
            .doc(id)
            .update({ status: "partially_paid_finished" });
          if (state?.table?.orderInfo?.businessLocationId && state?.table?.sessionId) {
            dispatch(
              loadOrdersBySession({
                businessLocationId: state?.table?.orderInfo?.businessLocationId,
                sessionId: state?.table?.sessionId
              })
            );
          }
          // call get session order api again
        }
      });
  }
};

export const getOrdersRecepit = (payload) => async (dispatch) => {
  try {
    const res = await ordersRepository.getOrderRecepit(payload);
    dispatch(SetLoading(false));
    if (res.status === 200 && res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    dispatch(SetLoading(false));
    console.log(err);
  }
};
export const loadPayAllOrdersBySession = (payload) => async (dispatch) => {
  const checkOrders = (respons) => {
    if (respons?.status === 200) {
      dispatch({
        type: GET_ORDERS_BY_SESSION,
        payload: respons
      });
      dispatch(SetOrderPlacedLoading(false));
    } else {
      dispatch(SetOrderPlacedLoading(false));
      alert("Please refresh & try again");
    }
  };
  try {
    ordersRepository.getPayAllOrdersBySession(payload).then((respons) => checkOrders(respons));
  } catch (err) {
    dispatch(SetOrderPlacedLoading(false));
    if (err?.response?.data?.message !== undefined) {
      // alert(err?.response?.data?.message);
    }
    dispatch(SetOrderPlacedLoading(false));
    dispatch({
      type: EMPTY_ORDERS_PLACED
    });
  }
};
export const getOrdersFromFirebae = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDERS_BY_FIREBASE,
      payload: payload
    });
  } catch (err) {
    dispatch(SetLoading(false));
    console.log(err);
  }
};
