import React from "react";
import ModalInterface from "../../common/ModalInterface";
import { useNavigate } from "react-router-dom";

const OrderConformationModal = ({
  setShowConfirmOrder,
  showConfirmOrder,
  // payOption,
  // setPayOption,
  // setShowPay,
  // updateBill,
  // setShowDivide,
  // setDivideBill,
  // setSelectedBill,
  setPaymentType,
  paymentType,
  triggerPayWaiter
}) => {
  let navigate = useNavigate();
  return (
    <>
      <ModalInterface
        display={showConfirmOrder}
        center="true"
        handleClose={() => {
          setShowConfirmOrder(false);
        }}
        title={
          <>
            {" "}
            <div className="text-center  d-flex justify-content-end">
              <div
                className="bg-danger text-white close-btn d-flex align-items-center justify-content-center p-3 mt-1 me-1"
                role="button">
                <i
                  className="fa fa-close fs-5"
                  onClick={() => {
                    setShowConfirmOrder(false);
                  }}></i>
              </div>
            </div>
          </>
        }
        closeEvent={() => setShowConfirmOrder(false)}
        content={
          <>
            <div className="text-center mt-0 pt-0 mb-2">
              <h5> ACHTUNG!</h5>
              {paymentType === "pay_waiter" ? (
                <h5>
                  {" "}
                  SOBALD EINE ZAHLUNG GETÄTIGT WIRD,
                  <br />
                  KÖNNEN KEINE WEITEREN BESTELLUNGEN
                  <br />
                  AUF DIESEN NAMEN ABGEGEBEN WERDEN.
                </h5>
              ) : (
                <h5>
                  {" "}
                  WENN SIE ONLINE ZAHLEN,
                  <br />
                  IST ES NICHT MEHR MÖGLICH
                  <br />
                  EINEN TEIL DER RECHNUNG
                  <br />
                  BEIM KELLNER ZU BEZAHLEN.
                  <br />
                  SOBALD EINE ZAHLUNG GETÄTIGT WIRD,
                  <br />
                  KÖNNEN KEINE WEITEREN BESTELLUNGEN
                  <br />
                  AUF DIESEN NAMEN ABGEGEBEN WERDEN.
                </h5>
              )}
              <div className="mt-4">
                <button
                  className="bg-danger text-white p-2 ps-3 pe-3 me-3 rounded-pill"
                  role="button"
                  onClick={() => {
                    setShowConfirmOrder(false);
                    navigate("/home");
                  }}>SPÄTER ZAHLEN
                </button>
                <button
                  className="bg-danger text-white p-2 ps-3 pe-3  rounded-pill"
                  role="button"
                  onClick={() => {
                    // updateBill(1);
                    setShowConfirmOrder(false);
                    if (paymentType === "pay_waiter") triggerPayWaiter();
                    else {
                      // setShowPay(true);
                      setPaymentType("online_payment");
                    }

                    // if (payOption === "payall") {
                    //   setShowPay(true);
                    //   updateBill(1);
                    //   setShowConfirmOrder(false);
                    // } else if (payOption == "divide") {
                    //   setShowDivide(true);
                    //   setDivideBill(true);
                    //   setShowConfirmOrder(false);
                    // } else if (payOption == "selected") {
                    //   setShowPay(true);
                    //   setSelectedBill(true);
                    //   setShowConfirmOrder(false);
                    // } else {
                    //   alert("something wrong");
                    // }
                  }}>WEITER MIT DER BEZAHLUNG
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default OrderConformationModal;
