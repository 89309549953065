import React from "react";

const SplashScreen = ({ showWrapper, mainLogo }) => {
  const randomImgList = [
    // "img/Animations PLACEHOLDERS-04.png",
    // "img/Animations PLACEHOLDERS-05.png",
    // "img/Animations PLACEHOLDERS-06.png",
    // "img/Animations PLACEHOLDERS-07.png",
    "img/Animations PLACEHOLDERS-08.png",
    "img/Animations PLACEHOLDERS-09.png"
  ];
  const randomLoad = Math.floor(Math.random() * randomImgList.length);
  const randomImg = randomImgList[randomLoad];
  return (
    showWrapper && (
      <div className="all-content-wrapper">
        <div className="colorful-lines-container">
          <div className="color-line color-line1"></div>

          <div className="color-line color-line2"></div>

          <div className="color-line color-line3"></div>

          <div className="color-line color-line4"></div>

          <div className="color-line color-line5"></div>

          <div className="color-line color-line6"></div>

          <div className="color-line color-line7"></div>
        </div>

        <div className="center-v-img">
          <img src="img/VAPIANO_whitev.fw.png" alt="VAPIANO_whitev" />
        </div>

        <div className="bottom-images-container">
          <div className="bottom-logo">
            <div className="mainLogo">
              <img src={mainLogo ? mainLogo : "img/vapiano_logo.png"} alt="vapiano_logo" />
            </div>
          </div>

          <div className="bottom-text-img">
            <img alt="PASTA_text" src={randomImg} />
          </div>
        </div>
      </div>
    )
  );
};
export default SplashScreen;
