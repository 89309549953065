import { Row, Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ModalInterface from "../../common/ModalInterface";

import encBase64 from "crypto-js/enc-base64";
import hmacSha256 from "crypto-js/hmac-sha256";
import { useDispatch, useSelector } from "react-redux";
import { SetLoading, SetOrderPayment } from "../../store/actions/orderAction";
import axios from "axios";
const PaymentModal = (props) => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();

  const orderID = useSelector((state) => state?.order?.ordersPlaced[0]?._id);
  const orderLoading = useSelector((state) => state?.order?.loading);
  const tableInfo = useSelector((state) => state?.table?.orderInfo);
  const sessionId = useSelector((state) => state.table.sessionId);
  const placedOrders = useSelector((state) => state?.order?.ordersPlaced[0]);
  const [showWaitermsg, setShowWaitermsg] = useState(false);
  const [totalBill, setTotalBill] = useState(0);
  const [totalItemsPrice, setTotalItemsPrice] = useState(0);
  const [isPaymentFinished, setIsPaymentFinished] = useState(false);

  const calculateFinalePrice = (order) => {
    let totalAmountOfSaleEntriesAndSublineItems = 0;
    order?.forEach((_saleEntry) => {
      // calculating saleEntry TOTAL_PRICE
      let quantity = _saleEntry?.quantity ? _saleEntry?.quantity : 1;
      _saleEntry?.itemPrice
          ? (totalAmountOfSaleEntriesAndSublineItems += Number(_saleEntry?.itemPrice) * quantity)
          : (totalAmountOfSaleEntriesAndSublineItems += 0);
      // calculating subline_item TOTAL_PRICE +  saleEntry TOTAL_PRICE ===> TOTAL_PRICE_OF_ALLITEMS_AND_SUBLINE_ITEM
      _saleEntry.subLineItems.forEach((_subItem) => {
        let quantity = _subItem?.quantity ? _subItem?.quantity : 1;
        _subItem?.itemPrice
            ? (totalAmountOfSaleEntriesAndSublineItems += Number(_subItem?.itemPrice) * quantity)
            : (totalAmountOfSaleEntriesAndSublineItems += 0);
      });
    });
    return Number(totalAmountOfSaleEntriesAndSublineItems.toFixed(2));
  };

  const [selectedTip, setSelectedTip] = useState(0.1); //10% default tip
  const [manualTip, setManualTip] = useState("");

  const updatePaymentInfoStatus = async () => {
    try {
      axios.put(process.env.REACT_APP_BackendURL + `/payment-info/update-status/${orderID}`, {
            status: "Pending", // replace with the desired status
          })
          .then((response) => {
            console.log("Payment status updated:", response.data);
          })

    } catch (error) {
      console.error("Error updating payment info:", error.message);
    }
  };

  const getPaymentInfo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BackendURL + `/payment-info/${orderID}`, {
      })
      if(response.data === "Paid" || response.data === "Canceled")
        return true;
    } catch (error) {
      console.error("Error fetching payment info:", error.message);
    }
  };


  useEffect( () => {
    if (placedOrders) {
      gettingAmounts()
    }
  }, [placedOrders]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentInfo = await getPaymentInfo();
        setIsPaymentFinished(paymentInfo);
      } catch (error) {
        console.error("Error fetching payment info:", error.message);
      }
    };

    fetchData();
  }, [orderID])
  const gettingAmounts = () => {
    let totalAmount = null;
    if (!props.isSelectedBill) {
      totalAmount = calculateFinalePrice(placedOrders.salesEntries);
    } else {
      totalAmount = calculateFinalePrice(props.selectedItems);
    }
    let totaltip = (parseFloat(totalAmount) * selectedTip).toFixed(2);
    setTotalBill(Number(totalAmount) + Number(totaltip));
    setTotalItemsPrice(Number(totalAmount));

  }

  // useEffect(() => {
  //   const bill = subAmount * (1 + selectedTip);
  //   setTotal(bill);
  //   // .toFixed(2); //add tip to total subamount
  // }, []);

  // console.log("total", total, "sub->", subAmount);
  const handelWaitermsg = () => {
    setShowWaitermsg(true);
  };

  const handleOnChange = (tipPercent) => {
    setSelectedTip(tipPercent);
    let totaltip = (parseFloat(totalItemsPrice) * tipPercent).toFixed(2);
    setTotalBill(Number(totalItemsPrice) + Number(totaltip));
    setManualTip("");
    // setTotal(parseFloat(subAmount) * (1 + tipPercent));
    // setDivideTip(divSub * tipPercent);
  };

  const handlePayment =  () => {

    dispatch(SetLoading(true));
    paymentCreditConfig();
    updatePaymentInfoStatus();
  };

  // const handlePayDivideAdjustment = () => {
  //   let chargetotal;
  //   let balance = props?.fullAmount - props?.paidAmount;
  //   let roundedDivAmount = props?.divisionAmount.toFixed(2);
  //   let paymentRecord = placedOrders?.payment;
  //   let ConfirmedDivisionCount = placedOrders?.division_count;

  //   if (
  //     paymentRecord.length > 0 &&
  //     roundedDivAmount * (paymentRecord.length + 1) === roundedDivAmount * ConfirmedDivisionCount
  //   ) {
  //     //last payment so pay balance of order and not division amount
  //     chargetotal = balance.toFixed(2);
  //     console.log("in last payment", chargetotal);
  //   } else if (paymentRecord.length === 0) {
  //     //first payment pay division amount
  //     chargetotal = props?.billAmount?.toFixed(2);
  //     // console.log("first", chargetotal)
  //   } else {
  //     //pay division amount
  //     chargetotal = roundedDivAmount;
  //     // console.log("not first", chargetotal)
  //   }
  //   return parseFloat(chargetotal);
  // };
  const paymentCreditConfig = async () => {
    const sharedSecret = 'jV"e5$6QrB';
    const storeId = process.env.REACT_APP_PAYMENT_STORE_ID;

    const d = new Date();
    const datestring =
        d.getFullYear() +
        ":" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        ":" +
        ("0" + d.getDate()).slice(-2) +
        "-" +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2);

    const separator = "|";
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const txntype = "sale";
    const checkoutoption = "combinedpage";
    const paymentMethod = "M,V";
    let chargetotal = Number(totalBill).toFixed(2);
    //below logic is to adjust rounding error in final payment for payDivid

    //not in use

    // if (props.paymentType === "pay_divide") {
    //   // console.log("heading into adjustment function")
    //   chargetotal = handlePayDivideAdjustment() + parseFloat(divideTip.toFixed(2));
    // }

    // const chargetotal = Math.round(paymentInfo?.paymentAmount || "12.2").toFixed(2).toString();
    const currency = "978";
    const stringToHash =
        chargetotal +
        separator +
        checkoutoption +
        separator +
        currency +
        separator +
        "HMACSHA256" +
        separator +
        paymentMethod +
        separator +
        storeId +
        separator +
        timezone +
        separator +
        datestring +
        separator +
        txntype ;

    const hashHMACSHA256 = hmacSha256(stringToHash, sharedSecret);
    const extendedhash = encBase64.stringify(hashHMACSHA256);

    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", process.env.REACT_APP_PAYMENT_URL);

    const i1 = document.createElement("input");
    i1.setAttribute("type", "hidden");
    i1.setAttribute("name", "txntype");
    i1.setAttribute("value", txntype);
    form.appendChild(i1);

    const i2 = document.createElement("input");
    i2.setAttribute("type", "hidden");
    i2.setAttribute("name", "timezone");
    i2.setAttribute("value", timezone);
    form.appendChild(i2);

    const i3 = document.createElement("input");
    i3.setAttribute("type", "hidden");
    i3.setAttribute("name", "txndatetime");
    i3.setAttribute("value", datestring);
    form.appendChild(i3);

    const i4 = document.createElement("input");
    i4.setAttribute("type", "hidden");
    i4.setAttribute("name", "hash_algorithm");
    i4.setAttribute("value", "HMACSHA256");
    form.appendChild(i4);

    const i5 = document.createElement("input");
    i5.setAttribute("type", "hidden");
    i5.setAttribute("name", "hashExtended");
    i5.setAttribute("value", extendedhash);
    form.appendChild(i5);

    const i6 = document.createElement("input");
    i6.setAttribute("type", "hidden");
    i6.setAttribute("name", "storename");
    i6.setAttribute("value", storeId);
    form.appendChild(i6);

    const i7 = document.createElement("input");
    i7.setAttribute("type", "hidden");
    i7.setAttribute("name", "checkoutoption");
    i7.setAttribute("value", checkoutoption);
    form.appendChild(i7);

    const i8 = document.createElement("input");
    i8.setAttribute("type", "hidden");
    i8.setAttribute("name", "paymentMethod");
    i8.setAttribute("value", paymentMethod);
    form.appendChild(i8);

    const i9 = document.createElement("input");
    i9.setAttribute("type", "hidden");
    i9.setAttribute("name", "chargetotal");
    i9.setAttribute("value", chargetotal);
    form.appendChild(i9);

    const i10 = document.createElement("input");
    i10.setAttribute("type", "hidden");
    i10.setAttribute("name", "currency");
    i10.setAttribute("value", currency);
    form.appendChild(i10);


    var s = document.createElement("input");
    s.setAttribute("type", "hidden");
    s.setAttribute("type", "submit");
    s.setAttribute("value", "Submit");
    form.appendChild(s);

    document.getElementsByTagName("body")[0].appendChild(form);
    console.log(s)
    s.click();

    document.getElementsByTagName("body")[0].removeChild(form);
  };

  // const notifyKitchen = () => {
  //   let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${tableName} | Tip Amount: €${(total - subAmount).toFixed(2)}
  //   | Order Total: €${total.toFixed(2)}   `
  //   const msg = {
  //     problemID: uuidv4(),
  //     tableNo: tableInfo?.tableNumber,
  //     sessionID: sessionId,
  //     message: msgDetails.trim(),
  //     payWaiter: true,
  //   };
  //   socket.emit("Problem_", msg);
  //   msgDetails = msgDetails + `| Table No: ${tableInfo?.tableNumber}`
  //   return msgDetails
  // }

  const paymentInfo = {
    order_id: orderID,
    paymentMethod: "Ope",
    type: props.paymentType,
    // paymentAmount: subAmount,
    // paymentAmount: props?.isDivide ? handlePayDivideAdjustment() : subAmount,
    paymentAmount: Number(Number(totalBill).toFixed(2)),
    divisionCount: props?.finalDivideCount,
    // dividedAmount: handlePayDivideAdjustment(),
    // ?.toFixed(2)),
    // paymentAmount: Math.round(total).toFixed(2).toString(),
    // tipAmount: props?.isDivide ? parseFloat(divideTip.toFixed(2)) : (total - subAmount).toFixed(2),
    tipAmount: Number((Number(totalBill) - Number(totalItemsPrice)).toFixed(2)),
    // ?.toFixed(2)),
    min_TTL: 0,
    tableInfo: tableInfo,
    sessionInfo: sessionId,
    salesEntries: props?.salesEntries
  };
  // console.log("🚀 ~ file: PaymentModal.js ~ line 226 ~ PaymentModal ~ paymentInfo", paymentInfo)


  return (
      <div className="text-center px-2" style={{ marginTop: "-30px" }}>
        <h4 style={{ color: "#3CAF5A", fontSize: "20px", fontWeight: "600" }}>
          WIE ZUFRIEDEN BIST DU
          <br />
          MIT UNSERER ARBEIT?
        </h4>
        <h2 className="" style={{ fontSize: "20px", lineHeight: "20px" }}>
          WÄHL DIE HÖHE DEINES TRINKGELDS <br />
          ODER
          <br /> GEBE EINEN EIGENEN BETRAG EIN
        </h2>

        <div className="align-items-center justify-content-center tipEmoji mx-3">
          <div className=" singleTip">
            <img
                src="img/ang.png"
                className="img-responsive singleTip"
                width={40}
                alt="rating_images"
            />
            <input
                type="checkbox"
                value="0"
                className="feedback-tick"
                checked={selectedTip === 0}
                onChange={() => handleOnChange(0)}
                name="tip"
            />
            <span className="fw-bold">0%</span>
          </div>
          <div className="singleTip">
            <img
                src="img/ang2.png"
                className="img-responsive singleTip"
                width={40}
                alt="rating_images"
            />
            <input
                type="checkbox"
                value="0.05"
                name="tip"
                checked={selectedTip === 0.05}
                onChange={() => handleOnChange(0.05)}
            />
            <span className="fw-bold">5%</span>
          </div>
          <div className="singleTip">
            <img
                src="img/ang3.png"
                className="img-responsive singleTip"
                width={40}
                alt="rating_images"
            />
            <input
                type="checkbox"
                value="0.1"
                name="tip"
                onChange={() => handleOnChange(0.1)}
                checked={selectedTip === 0.1}
            />
            <span className="fw-bold">10%</span>
          </div>
          <div className="singleTip">
            <img
                src="img/ang4.png"
                className="img-responsive singleTip"
                width={40}
                alt="rating_images"
            />
            <input
                type="checkbox"
                value="0.15"
                name="tip"
                onChange={() => handleOnChange(0.15)}
                checked={selectedTip === 0.15}
            />
            <span className="fw-bold">15%</span>
          </div>
          <div className="singleTip">
            <img
                src="img/ang5.png"
                className="img-responsive singleTip"
                width={40}
                alt="rating_images"
            />
            <input
                type="checkbox"
                value="0.20"
                name="tip"
                onChange={() => handleOnChange(0.2)}
                checked={selectedTip === 0.2}
            />
            <span className="fw-bold">20%</span>
          </div>
        </div>

        {/* </Form> */}
        <div className="tipAmount">
          <div className="d-flex">
            <Form.Control
                type="number"
                placeholder=""
                // id='manualInp'
                value={manualTip}
                onChange={(e) => {
                  setManualTip(Number(e.target.value));
                }}
                className="tipWrapInput"
            />
            <Button
                className="tick-btn text-center border border-info"
                style={{ backgroundColor: "#33AB53" }}
                onClick={() => {
                  if (manualTip > 0) {
                    setTotalBill(parseFloat(totalItemsPrice) + Number(manualTip));
                  } else {
                    setTotalBill(parseFloat(totalItemsPrice) + 0);
                    setManualTip(0);
                  }
                  setSelectedTip();
                  // if (manualTip > 0 && !props?.isDivide) {
                  //   // let amt = parseFloat(subAmount) + parseFloat(manualTip);
                  //   // setTotal(amt);
                  //   setSelectedTip("");
                  // }
                  // if (manualTip > 0 && props?.isDivide) {
                  //   // setDivideTip(manualTip);
                  // }
                }}>
              {" "}
              <img src="./img/tick.png" alt="icon" height={20} />
            </Button>
          </div>
        </div>

        <Row className="paymentWrap mt-3">
          <div className="amountWithTip2">
            <span>DEINE RECHNUNGSSUMME</span>{" "}
            <span className="amount-of-tip">
            {" "}
              {/* {props?.isDivide ? handlePayDivideAdjustment() : subAmount}€ */}
              {Number(totalItemsPrice).toFixed(2)}€
          </span>
          </div>
          <div className="amountWithTip2">
            <span>DEIN TRINKGELD*</span>{" "}
            <span className="amount-of-tip">
            {" "}
              {/* {props?.isDivide ? divideTip.toFixed(2) : parseFloat(total - subAmount).toFixed(2)}€ */}
              {(Number(totalBill) - Number(totalItemsPrice)).toFixed(2)}€
          </span>
          </div>
          <div className="amountWithTip2">
          <span>
            <b>SUMME</b>
          </span>{" "}
            <span className="amount-of-tip-total">
            {/* {props?.isDivide
              ? (handlePayDivideAdjustment() + parseFloat(divideTip)).toFixed(2)
              : parseFloat(total).toFixed(2)} */}
              {Number(Number(totalBill).toFixed(2))}€
          </span>
          </div>
          <div className="pb-5 mt-4 d-flex justify-content-center">
            {/* <div
            className="btnPay"
            onClick={() => {
              const waiterPayload = {
                businessLocationId: orderInfo?.businessLocationId,
                thirdPartyReference: uuidv4(),
                orderNote: "test",
                orderType: "served",
                sessionID: sessionId,
                customerInfo: {},
                tableNumber: orderInfo?.tableNumber,
                items: [],
              };

              if (props?.paymentType !== "pay_selected_waiter") {
                let orderNote = notifyKitchen()
                let payload = { ...waiterPayload, orderNote: orderNote, totalAmount: total, tipAmount: (total - subAmount).toFixed(2) }
                dispatch(payWaiter(payload)).then(() => {
                  setTimeout(() => {
                    window.location.replace("/bill");
                  }, 5000);
                });
              } else {
                dispatch(
                  paySelectedWaiter({
                    ...waiterPayload,
                    totalAmount: total,
                    selectedIds: id,
                  }).then(() => {
                    setTimeout(() => {
                      window.location.replace("/bill");
                    }, 5000);
                  })
                );
              }
            }}
          >
            <div
              role="button"
              className="paymentButton2 "
              onClick={() => {
                props.setShowFinal(true);
                props.setShowPay(false);
              }}
            >
              <img
                src="img/wallet-64.png"
                alt="payment_to_waiter"
                className="img-responsive rounded shadow-lg"
                style={{ height: "40px" }}
              />
            </div>
            <p className="mt-1">BEIM KELLNER BEZHALEN</p>
          </div> */}
            <div className="btnPay" style={{ border: "none" }} disabled={props?.payWaiterSelected}>
              <div className="paymentButton2">
                {orderLoading ? (
                    <span
                        className="spinner-border spinner-border-lg text-danger text-center"
                        role="status"
                        aria-hidden="true"></span>
                ) : (
                    <div
                        className="borders-0"
                        role="button"
                        onClick={() => {
                          if (props?.paymentType === "pay_selected_waiter") {
                            handelWaitermsg();

                            // alert("Please select waiter option");
                          } else {
                            dispatch(SetOrderPayment(paymentInfo));
                            sessionStorage.setItem("paymentStarted", true)
                            // props.setfinalAmount(Math.round(total).toFixed(2).toString());
                            // props.setfinalAmount(
                            //   props.divisionAmount > 0.0 ? props?.divisionAmount : total
                            // );
                            props.setfinalAmount(Number(Number(totalBill).toFixed(2)));
                            handlePayment();
                          }
                        }}
                        style={{ pointerEvents: props.paymentType === "pay_selected_waiter" || isPaymentFinished ? "none" : "auto" }}
                    >
                      <img
                          src="img/paymaster.png"
                          alt="paypal"
                          className="img-responsive rounded shadow-lg"
                          style={{ height: "40px" }}
                      />
                    </div>
                )}
              </div>
              <p className="mt-1 text-dark">
                DIREKT
                <br /> BEZAHLEN
              </p>
            </div>
          </div>
        </Row>
        <Row className="text-center">
          {" "}
          <b>*freiwillige Trinkgeldzahllung an Mitarbeiter*innen</b>
        </Row>
        <ModalInterface
            display={showWaitermsg}
            size="lg"
            center="true"
            handleClose={async () => {
              setShowWaitermsg(false);
            }}
            title={
              <>
                <div className="d-flex justify-content-end p-2">
              <span
                  className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                  style={{ height: "25px", width: "25px" }}>
                {" "}
                <i
                    className="fa fa-close fs-4"
                    onClick={async () => {
                      setShowWaitermsg(false);
                    }}></i>
              </span>
                </div>
              </>
            }
            content={
              <>
                <h4>Please select waiter option</h4>
              </>
            }
        />
      </div>
  );
};

export default PaymentModal;

