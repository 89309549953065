import { Button, Col, Row } from "react-bootstrap";
import ModalInterface from "../../common/ModalInterface";
import "./searchModal.css";
import { v4 as uuidv4 } from "uuid";

const SearcgBarModal = ({
  showSearch,
  setShowSearch,
  searchItem,
  query,
  setQuery,
  checkFilters,
  categories,
  findWithFilters,
  SearchFound,
  SetSearchFound
}) => {
  // Creates an array of objects with unique "name" property values.
  let uniqueObjArray = [...new Map(categories.map((item) => [item["title"], item])).values()];

  return (
    <>
      <ModalInterface
        display={showSearch}
        size="sm"
        center={true}
        style={{ position: "relative" }}
        handleClose={() => {
          setShowSearch(false);
        }}
        content={
          <>
            <div>
              <div className="text-center pt-0 mt-0 d-flex justify-content-end">
                <div
                  className="bg-danger text-white close-btn d-flex align-items-center justify-content-center p-0 m-0"
                  role="button">
                  <i
                    className="fa fa-close"
                    onClick={() => {
                      setShowSearch(false);
                    }}></i>
                </div>
              </div>
              <div className="ps-3 pe-3 pb-3">
                <div>
                  <h3>SUCHE</h3>
                </div>
                <div className="search w-100">
                  <Row
                    className="input-group align-items-center justify-content-center  p-0 m-0"
                    onKeyPress={(e) => e.key === "Enter" && searchItem()}>
                    <Col lg="11" md="11" sm="11" xs="10" className="">
                      <input
                        type="search"
                        id="form1"
                        className="form-control border-0 w-100  m-0 bg-color-input"
                        placeholder="TEXT EINGEBEN..."
                        onChange={(e) => {
                          setQuery(e.target.value);
                          SetSearchFound(false);
                        }}
                        value={query}
                      />
                    </Col>

                    <Col
                      className="search-btn  d-flex justify-content-end"
                      lg="1"
                      md="1"
                      sm="1"
                      xs="2">
                      <button
                        role="button"
                        className="border-0 bg-transparent d-flex justify-content-end p-0 m-0"
                        onClick={() => {
                          searchItem();
                        }}>
                        <span className="p-0 m-0">
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <ellipse
                              cx="10.9004"
                              cy="10.3999"
                              rx="9"
                              ry="9"
                              stroke="#979797"
                              strokeWidth="2.25"
                            />
                            <path
                              d="M17.499 17.0005L23.863 23.3644"
                              stroke="#979797"
                              strokeWidth="2.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className={SearchFound ? " mt-2 bg-danger text-white p-1 message" : "d-none"}>
                  <span>kein Ergebnis</span>
                </div>
                <div className="w-100">
                  <Row className="mt-3 ps-2">
                    <Col lg="10" md="10" sm="10" xs="10" className="p-0 m-0">
                      <h4 className="">NACH KATEGORIEN FILTERN</h4>
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2" className=" pe-lg-3 pe-md-3 pe-sm-3">
                      <img src="./img/vapiano-sort.png" alt="sort" className="sort-img" />
                    </Col>
                  </Row>
                </div>
                <div className="alergance d-flex flex-wrap gap-4 p-5">
                  {Array.from(uniqueObjArray).map((category) => {
                    return (
                      <div
                        className="alerg-img"
                        role="button"
                        key={uuidv4()}
                        onClick={(e) => {
                          checkFilters(category);
                          e.target.classList.toggle("searchactive");
                        }}>
                        <img src={category.icon} alt="img" height={35} />
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3 w-100">
                  <Button
                    className="bg-danger px-5 py-1 text-white apply-btn border border-none"
                    role={"button"}
                    onClick={() => {
                      findWithFilters();
                    }}>
                    FILTER ANWENDEN
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
        closeEvent={() => {
          setShowSearch(false);
        }}
      />
    </>
  );
};

export default SearcgBarModal;
