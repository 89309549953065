import React from "react";
import ModalInterface from "../../common/ModalInterface";

const BillNoItem = ({ billModal, setBillModal }) => {
  return (
    <>
      <ModalInterface
        display={billModal}
        size="sm"
        style={{ position: "relative" }}
        handleClose={() => {
          setBillModal(false);
        }}
        title={<></>}
        center={true}
        content={
          <>
            <div
              className="d-flex flex-column justify-content-center "
              style={{ marginTop: "-14px" }}
            >
              <div className="d-flex justify-content-end">
                <button
                  className="d-flex justify-content-center align-items-center tex-center "
                  style={{
                    border: "none",
                    backgroundColor: "red",
                    borderRadius: "50px",
                    width: "30px",
                    height: "30px",
                    color: "white",
                    fontSize: "30px"
                  }}
                  onClick={() => {
                    setBillModal(false);
                  }}
                >
                  <i className="fa fa-close" style={{ fontSize: "16px" }}></i>
                </button>
              </div>
              <div style={{ paddingRight: "40px", textAlign: "center" }}>
                <img
                  src="./img/tomato.png"
                  style={{ width: "200px", height: "200px" }}
                  alt="icon"
                ></img>
              </div>
              <div
                className="justify-content-center d-flex"
                style={{
                  color: "#f1c5a8",
                  fontWeight: "500",
                  fontSize: "50px"
                }}
              >
                UUPS
              </div>
              <div
                className="text-center pt-3 pb-3 "
                style={{
                  fontWeight: "500",
                  fontSize: "30px",
                  lineHeight: "36px"
                }}
              >
                ES SCHEINT ALS HÄTTEST DU NOCH KEINE BESTELLUNG ABGESCHICKT
              </div>
              <div className="text-center">
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#3CAF5A",
                    borderRadius: "50px",
                    width: "100px"
                  }}
                  onClick={() => {
                    setBillModal(false);
                  }}
                >
                  <i className="fa fa-check" style={{ color: "white", fontSize: "40px" }}></i>
                </button>
              </div>
            </div>
          </>
        }
        closeEvent={() => {}}
      />
    </>
  );
};

export default BillNoItem;
