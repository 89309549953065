import Repository from './Repository';
const FLOOR_PLANS = '/floor-plans';
const SESSIONS = '/table-sessions';
export default {
  getFloorPlanByNumber(businessId,payload) {
    return Repository.get(`${FLOOR_PLANS}/getfloorplan/${businessId}/${payload}`);
  },

  getSession(businessId,payload) {
    return Repository.get(`${SESSIONS}/session/${businessId}/${payload}`);
  },
};

