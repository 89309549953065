import Repository from "./Repository";

export default {
  orderPayment(payload) {
    return Repository.post(`/order`, payload).then((res) => {
      return res;
    });
  },

  orderPlacement(payload) {
    return Repository.post(`/order/order-dine-in`, payload).then((res) => {
      return res;
    });
  },

  payWaiterOrder(payload) {
    return Repository.post(`/order/waiter-print`, payload).then((res) => {
      return res;
    });
  },

  paySelectedWaiterOrder(payload) {
    return Repository.post(`/order/pay-selected-waiter`, payload).then((res) => {
      return res;
    });
  },

  getOrdersBySession(payload) {
    return Repository.get(
      `/order/session-orders/${payload.sessionId}?blId=${payload.businessLocationId}&flag=menuApp`
    ).then((res) => {
      return res;
    });
  },

  getOrderRecepit(payload) {
    return Repository.post(`/order/generate-receipt`, payload).then((res) => {
      return res;
    });
  },

  getPayAllOrdersBySession(payload) {
    return Repository.get(
      `/order/pay-all/session-orders/${payload.sessionId}?blId=${payload.businessLocationId}`
    ).then((res) => {
      localStorage.setItem('paymentStarted', false)
      return res;
    });
  },

  loadCourses() {
    return Repository.get(`/courses`).then((res) => {
      return res;
    });
  }
};
