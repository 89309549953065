/* eslint-disable no-unused-vars */
import { MessageSquareWarning } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from "react-hot-toast";

// detect apple product
const useSafariDetection = () => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    function iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    // if (iOS()) toast.custom(
    //   <div style={{
    //     backgroundColor: '#fff',
    //     borderRadius: '.5rem',
    //     paddingTop: '.5rem',
    //     paddingBottom: '.5rem',
    //     paddingInline: '.8rem',
    //     color: '#000',
    //     display: 'flex',
    //     gap: '.4rem',
    //     alignItems: 'center',
    //     justifyContent: 'center'
    //   }}>
    //     <MessageSquareWarning />
    //     <span>
    //       Switch to chrome to be able to receive notifications!
    //     </span>
    //   </div>,
    //   { duration: 8000 })

    setIsSafari(iOS());
  }, []);


  return isSafari;
};

export default useSafariDetection;
