import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const PaymentSuccessPartial = () => {
  const isGuestBook = useSelector((state) => state.table.isGuestBook);
  // const dispatch = useDispatch();

  // // useEffect(() => {
  // //   dispatch(checkIsGuestBook())
  // // }, []);
  return (
    <>
      <div className="bgcolor-white d-flex flex-column justify-content-center align-items-center">
        <div className="text-center d-flex flex-column justify-content-center align-items-center">
          <div className="text-center text-uppercase thankuText">DANKE! </div>
          <div className="text-center text-dark  text-uppercase forOrderText">
            wir haben deine <p> ZAHLUNG erhalten</p>
          </div>
          <div className="heartPaymentImg">
            <img className="w-100 h-100" src="/img/heartpayment.png" alt="icon"></img>
          </div>
          <div className="paymentlogo">
            <img className="w-100 h-100" src="/img/vapiano_logo.png" alt="icon"></img>
          </div>
          {isGuestBook ? (
            <Link to={`/guestBook`}>
              <button className="gastenbuchentrag buttonCommon text-uppercase">
                dein gastenbuchentrag
              </button>
            </Link>
          ) : null}{" "}
          <Link to={`/bill`}>
            <button className="zuruck buttonCommon text-uppercase"> zuruck </button>
          </Link>
          <div
            className="mt-3"
            style={{
              fontSize: "21px",
              fontWeight: "600"
            }}
          >
            <div> DEIN BELEG LIEGT ZUR ABHOLUNG BEREIT.</div>
            <div>BITTE WENDE DICH AN EINE(N) MITARBEITER*IN.</div>
          </div>
          {/* <div className="text-center text-uppercase text-white HIER ">
            HIER BEKOMMST DU <br />
            DEINE RECHNUNG
            <div>
              <button
                disabled={ordersPlaced[0]?.orderReference ? false : true}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/print-receipt?orderReference=${ordersPlaced[0]?.orderReference}&&buid=${buid}`
                  );
                }}
                className="rechnungBtn"
              >
                RECHNUNG
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessPartial;
